<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<a-form id="avatarForm" layout="vertical" :form="avatarForm" enctype="multipart/form-data">
				<div class="d-flex flex-wrap flex-column align-items-center">
					<div class="kit__utils__avatar kit__utils__avatar--size110 mb-3">
						<img :src="avatarImage" :alt="fullName" />
					</div>
					<a-form-item>
						<a-upload
							v-decorator="[
								'avatarImage',
								{
									valuePropName: 'avatarFile',
									getValueFromEvent: avatarFileEvents,
								},
							]"
							accept="image/*"
							name="file"
							:action="`${apiURL}/api/users/avatar/${user.user_id}`"
							:show-upload-list="false"
							:headers="uploadHeaders"
							:before-upload="beforeUpload"
						>
							<a-button> <a-icon type="upload" />Cambiar avatar </a-button>
						</a-upload>
					</a-form-item>
					<div class="text-center">
						<div class="text-dark font-weight-bold font-size-18">{{ fullName }}</div>
						<div class="text-uppercase font-size-12 mb-3">
							<b>Nombre Clave</b> <br />
							{{ user.username | replaceIfLogicalFalse('-No establecido-') }}
						</div>
					</div>
				</div>
			</a-form>
		</a-spin>
	</div>
</template>
<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import store from 'store'

export default {
	name: 'KitGeneral',
	data() {
		return {
			uploadHeaders: {},
			apiURL: process.env.VUE_APP_API_URL,
			avatarForm: this.$form.createForm(this),
		}
	},
	computed: {
		...mapState({
			user: (state) => state.user,
		}),
		fullName() {
			return `${this.user.name} ${this.user.first_lastname} ${this.user.second_lastname || ''}`
		},
		avatarImage() {
			return this.user.avatar_image ? `${process.env.VUE_APP_API_URL}${this.user.avatar_image}` : 'resources/images/avatar.png'
		},
		spinnerLoader() {
			return this.$store.state.user.loading
		},
		spinnerLoaderLabel() {
			return this.$store.state.user.spinnerLoaderLabel || 'Espere por favor...'
		},
	},
	mounted() {
		const accessToken = store.get('accessToken')
		this.uploadHeaders = {
			Authorization: `Bearer ${accessToken}`,
		}
	},
	methods: {
		avatarFileEvents(e) {
			if (e.file.status != 'done') {
				this.$store.commit('user/SET_STATE', {
					loading: true,
					spinnerLoaderLabel: 'Actualizando avatar...',
				})
			} else {
				if (e.file && e.file.response) {
					Vue.prototype.$notification.success({
						message: 'Actualización de datos',
						description: e.file.response.message || 'Correcto',
					})
					this.$store.dispatch('user/LOAD_CURRENT_ACCOUNT')
				}
			}
		},
		beforeUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 2
			if (!isLt2M) {
				this.$store.commit('user/SET_STATE', {
					loading: true,
					spinnerLoaderLabel: 'Actualizando avatar...',
				})
				Vue.prototype.$notification.error({
					message: 'Actualización de datos',
					description: 'La imagen debe ser menor de 2MB',
				})
			}
			return isLt2M
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
