var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-spin',{attrs:{"tip":_vm.spinnerLoaderLabel,"size":"large","spinning":_vm.spinnerLoader}},[_c('a-form',{attrs:{"id":"avatarForm","layout":"vertical","form":_vm.avatarForm,"enctype":"multipart/form-data"}},[_c('div',{staticClass:"d-flex flex-wrap flex-column align-items-center"},[_c('div',{staticClass:"kit__utils__avatar kit__utils__avatar--size110 mb-3"},[_c('img',{attrs:{"src":_vm.avatarImage,"alt":_vm.fullName}})]),_c('a-form-item',[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'avatarImage',
							{
								valuePropName: 'avatarFile',
								getValueFromEvent: _vm.avatarFileEvents,
							},
						]),expression:"[\n\t\t\t\t\t\t\t'avatarImage',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\tvaluePropName: 'avatarFile',\n\t\t\t\t\t\t\t\tgetValueFromEvent: avatarFileEvents,\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"accept":"image/*","name":"file","action":`${_vm.apiURL}/api/users/avatar/${_vm.user.user_id}`,"show-upload-list":false,"headers":_vm.uploadHeaders,"before-upload":_vm.beforeUpload}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v("Cambiar avatar ")],1)],1)],1),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"text-dark font-weight-bold font-size-18"},[_vm._v(_vm._s(_vm.fullName))]),_c('div',{staticClass:"text-uppercase font-size-12 mb-3"},[_c('b',[_vm._v("Nombre Clave")]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("replaceIfLogicalFalse")(_vm.user.username,'-No establecido-'))+" ")])])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }