<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<a-form layout="vertical" :form="personalInformationForm" @submit="handleSubmitPIF">
				<div class="row">
					<div class="col-md-4">
						<a-form-item label="Nombre(s)">
							<a-input
								v-decorator="[
									'name',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
								autocomplete="off"
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item label="Primer apellido">
							<a-input
								v-decorator="[
									'first_lastname',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
								autocomplete="off"
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item label="Segundo apellido">
							<a-input v-decorator="['second_lastname']" autocomplete="off" />
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item label="Nombre clave">
							<a-input
								v-decorator="[
									'username',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
								autocomplete="off"
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item label="Email">
							<a-input
								v-decorator="[
									'email',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
											{
												type: 'email',
												message: 'Ingrese email válido.',
											},
										],
									},
								]"
								autocomplete="off"
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item label="Teléfono celular">
							<a-input
								v-decorator="[
									'mobile_number',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
								autocomplete="off"
								v-mask="'##########'"
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item label="Contraseña">
							<a-input-password
								v-decorator="[
									'password',
									{
										rules: [
											{
												min: 6,
												message: 'Ingrese contraseña de al menos 6 caracteres.',
											},
											{
												validator: validateToNextPassword,
											},
										],
									},
								]"
								autocomplete="off"
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item label="Confirmar contraseña">
							<a-input-password
								@blur="handleConfirmBlur"
								v-decorator="[
									'confirmPassword',
									{
										rules: [
											{
												min: 6,
												message: 'Ingrese contraseña de al menos 6 caracteres.',
											},
											{
												validator: compareToFirstPassword,
											},
										],
									},
								]"
								autocomplete="off"
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<div class="btnPasswordGenerator">
							<a-button icon="lock" class="mr-1 btn btn-info btn-block" @click="onGeneratePassword">Generar contraseña</a-button>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="offset-md-3 col-md-6 text-center">
						<a-button html-type="submit" icon="save" class="btn btn-success btn-block">Actualizar Información Personal</a-button>
					</div>
				</div>
			</a-form>
		</a-spin>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import utilities from '@/services/utilities'

export default {
	name: 'generalInformationForm',
	directives: {
		mask,
	},
	computed: {
		...mapGetters('user', ['user']),
		spinnerLoader() {
			return this.$store.state.user.loading
		},
		spinnerLoaderLabel() {
			return this.$store.state.user.spinnerLoaderLabel || 'Espere por favor...'
		},
	},
	data() {
		return {
			apiURL: process.env.VUE_APP_API_URL,
			personalInformationForm: this.$form.createForm(this),
			inputType: 'password',
			confirmDirty: false,
		}
	},
	mounted() {
		// Set data into fields form
		// personalInformation
		this.personalInformationForm.setFieldsValue({
			name: this.user.name,
			first_lastname: this.user.first_lastname,
			second_lastname: this.user.second_lastname || '',
			email: this.user.email,
			mobile_number: this.user.mobile_number,
			username: this.user.username,
		})
	},
	methods: {
		onGeneratePassword() {
			utilities.passwordGenerator().then((passwd) => {
				this.personalInformationForm.setFieldsValue({ password: passwd })
				this.personalInformationForm.setFieldsValue({ confirmPassword: passwd })
			})
		},
		// Personal Information
		handleConfirmBlur(e) {
			const value = e.target.value
			this.confirmDirty = this.confirmDirty || !!value
		},
		compareToFirstPassword(rule, value, callback) {
			const form = this.personalInformationForm
			if (value && value !== form.getFieldValue('password')) {
				callback('Las contraseñas deben coincidir.')
			} else {
				callback()
			}
		},
		validateToNextPassword(rule, value, callback) {
			const form = this.personalInformationForm
			if (value && this.confirmDirty) {
				form.validateFields(['confirmPassword'], { force: true })
			}
			callback()
		},
		handleSubmitPIF(e) {
			e.preventDefault()
			this.personalInformationForm.validateFields((err, values) => {
				if (!err) {
					let payload = {
						...values,
						user_id: this.user.user_id,
					}
					this.$store.dispatch('user/UPDATE_PERSONAL_INFORMATION', { payload })
				}
			})
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>