<template>
	<div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-header card-header-flex flex-column">
						<div class="pt-3 pb-4 text-center">
							<div class="mr-5">
								<kit-general-10v1 />
							</div>
						</div>
					</div>
					<div class="card-body">
						<generalInformationForm />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import KitGeneral10v1 from '@/components/kit/widgets/General/10v1'
// Formularios
import generalInformationForm from './forms/generalInformation'

export default {
	components: {
		KitGeneral10v1,
		generalInformationForm,
	},
	data() {
		return {}
	},
	computed: {},
	methods: {},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>